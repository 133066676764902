import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const fonts = {};

const styles = {
  global: (props: any) => ({
    body: {
      color: mode('gray.800', 'whiteAlpha.800')(props),
      bg: mode('#F4F7FE', '#0b1437')(props),
    },
  }),
};

const colors = {
  primary: {
    DEFAULT: '#4073B9',
    '50': '#C5D5EB',
    '100': '#B6CAE6',
    '200': '#98B4DB',
    '300': '#7A9ED1',
    '400': '#5B88C6',
    '500': '#4073B9',
    '600': '#32598F',
    '700': '#233F66',
    '800': '#15253C',
    '900': '#060B12',
  },
};

const breakpoints = {
  sm: '32em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
};

const components = {};

export const extendedTheme = extendTheme({
  fonts,
  styles,
  colors,
  components,
  breakpoints,
});
