import { Box, Spinner } from '@chakra-ui/react';

const MapLoader = () => {
  return (
    <Box
      alignItems="center"
      bg="blackAlpha.700"
      display="flex"
      h="100vh"
      justifyContent="center"
      left="0"
      position="fixed"
      top="0"
      w="100vw"
      zIndex={1001}
    >
      <Spinner color="white" size="xl" />
    </Box>
  );
};

export default MapLoader;
