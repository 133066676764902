import 'signalr';
import { EventDto, LiveDevicePingDto, LiveEventStateChangeDto, RaceDto } from 'types';
import { UAParser } from 'ua-parser-js';

export const apiUrl = process.env.REACT_APP_API_URL;
export const signalrUrl = process.env.REACT_APP_SIGNALR_URL;

const requestApi = async (url: string) => {
  const res = await fetch(url);
  const { status } = res;
  if (status === 200) {
    const data = await res.json();
    return data;
  }
  throw Error('Error occured while getting data');
};

export const fetchEventData = async (eventSlug?: string): Promise<EventDto> => {
  const res = await requestApi(`${apiUrl}/Events/GetEvent${eventSlug ? `?key=${eventSlug}` : ''}`);
  return { Devices: res.Devices, ...res.Event };
};

export const fetchEventRuns = async (eventId: number): Promise<RaceDto[]> => {
  const res = await requestApi(`${apiUrl}/Runs/GetRuns${`?eventId=${eventId}`}`);
  return res;
};

export const fetchRace = async (runId: number): Promise<RaceDto> => {
  const res = await requestApi(`${apiUrl}/Runs/Get${`?runId=${runId}`}`);
  return res;
};

const getIp = async () => {
  const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
  const data = await res.text();
  const obj = Object.fromEntries(
    data
      .trim()
      .split('\n')
      .map((pair) => {
        const [key, val] = pair.split('=');
        return [key, val];
      }),
  );
  return obj.ip;
};

let connection: any;
let connectionLoading = false;

interface SignalRCallbacks {
  onGetCurrentStatus: (status: LiveDevicePingDto, isFirstRecord: boolean) => void;
  onGetEventStateChange: (data: LiveEventStateChangeDto) => void;
}
export const connectSignalr = async (
  eventId: number,
  { onGetCurrentStatus, onGetEventStateChange }: SignalRCallbacks,
) => {
  if (connection?.state === 1 || connectionLoading) {
    return 'Already connected';
  }
  connectionLoading = true;
  const uaParser = new UAParser();
  const browser = uaParser.getBrowser();
  const userIp = await getIp();
  let isFirstRecord = true;

  connection = $.hubConnection(`${signalrUrl}`);

  const hub = connection.createHubProxy('SportCommunicationHub');

  hub.on('Get_CurrentStatus', (status: any) => {
    onGetCurrentStatus(status, isFirstRecord);
    isFirstRecord = false;
  });

  hub.on('Get_EventStateChange', (data: LiveEventStateChangeDto) => {
    onGetEventStateChange(data);
  });

  connection.start().done(({ id }: any) => {
    hub.invoke('JoinGroup', id, eventId, '', browser, userIp);
    connectionLoading = false;
  });

  return 'Connected successfully';
};

export const disconnectSignalr = async () => {
  if (connection) {
    connection.stop();
  }
};
