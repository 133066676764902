import { apiUrl } from 'api';
import { divIcon, icon } from 'leaflet';
import { CSSProperties } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { AppSettings, Player, RootState } from 'types';

const labelStyles: CSSProperties = {
  background: 'rgba(0,0,0,.7)',
  color: '#fff',
  padding: '4px 6px',
  whiteSpace: 'nowrap',
  display: 'flex',
  position: 'absolute',
  fontSize: '10px',
  top: 25,
  left: 25,
};

const prepareLabelText = (
  { showSpeeds, showPlayersNames }: Partial<AppSettings>,
  { positionNumber, name, customNumber }: Partial<Player>,
  speed: number | null,
) => {
  const text: (string | number | undefined)[] = [positionNumber];
  if (showPlayersNames) {
    text.push(name);
  } else {
    text.push(customNumber);
  }
  if (showSpeeds) {
    text.push(`${speed} kt`);
  }
  return text.join(' | ');
};

const PlayerMarker: React.FC<Player> = ({ iconName, name, records, id, ...player }) => {
  const {
    appSettings: { showLabels, showSpeeds, showPlayersNames },
    players: { hoveredPlayerId, recordIndex, playMode },
  } = useSelector((state: RootState) => ({
    appSettings: state.appSettings,
    players: state.players,
  }));
  const index = playMode === 'live' ? records.length - 1 : (recordIndex as number);
  const isHovered = hoveredPlayerId ? id === hoveredPlayerId : true;

  const { speed, direction, lat, long } = records[index] ?? {};

  const {
    options: { iconUrl },
  } = icon({
    iconUrl: `${apiUrl}${iconName}`,
  });

  const html = renderToStaticMarkup(
    <div
      style={{
        position: 'relative',
      }}
    >
      {showLabels ? (
        <div
          style={{
            ...labelStyles,
            opacity: isHovered ? 1 : '.3',
          }}
        >
          {prepareLabelText({ showSpeeds, showPlayersNames }, { ...player, name }, speed)}
        </div>
      ) : null}
      <img
        alt={name}
        src={iconUrl}
        style={{
          zIndex: 10,
          transform: `rotate(${direction}deg)`,
        }}
      />
    </div>,
  );
  const finalIcon = divIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    className: '',
    html,
  });

  return long && lat ? (
    <Marker
      icon={finalIcon}
      position={[long as number, lat as number]}
      zIndexOffset={isHovered ? 9999 : 0}
    />
  ) : null;
};

export default PlayerMarker;
