import { createSlice } from '@reduxjs/toolkit';
import { Payload, Race, RaceDto, RaceList } from 'types';
import { mapRaceData, mapRaceList } from 'utils';

export interface RaceState {
  raceList: RaceList;
  raceListLoading: boolean;
  raceData: Race | null;
  raceLoading: boolean;
}

const initialState: RaceState = {
  raceList: [],
  raceListLoading: false,
  raceData: null,
  raceLoading: false,
};

const reducers = {
  toggleRaceListLoading: (state: RaceState, { payload }: Payload<boolean>) => {
    state.raceListLoading = payload;
  },
  setRaceListData: {
    prepare: (raceList: RaceDto[]) => {
      return { payload: mapRaceList(raceList) };
    },
    reducer: (state: RaceState, { payload }: Payload<RaceList>) => {
      state.raceList = payload;
      state.raceListLoading = false;
    },
  },
  toggleRaceLoading: (state: RaceState, { payload }: Payload<boolean>) => {
    state.raceLoading = payload;
  },
  setRaceData: {
    prepare: (race: RaceDto) => {
      return { payload: mapRaceData(race) };
    },
    reducer: (state: RaceState, { payload }: Payload<Race>) => {
      state.raceData = payload;
      state.raceLoading = false;
    },
  },
};

const raceSlice = createSlice({
  name: 'race',
  initialState,
  reducers,
});

export const { setRaceData, setRaceListData, toggleRaceListLoading, toggleRaceLoading } =
  raceSlice.actions;
export default raceSlice.reducer;
