import { Box, Collapse, Grid, GridItem, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillEye, AiFillEyeInvisible, AiOutlineSearch } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHoveredPlayerId,
  setScoreboardSortBy,
  togglePlayerTracking,
  togglePlayerVisiblity,
} from 'state/slices/players';
import { MapRef, Player, RecordIndex, RootState, ScoreboardSortBy } from 'types';
import { getLeaderDistance, getPlayersCurrentBounds } from 'utils';

const calculateLeaderDistanceValue = (
  distance: number,
  distanceToCheckpoint: number | null,
  nextCheckpoint: number | null,
) => {
  let distanceValue = '';
  if (distance < 0) {
    distanceValue = 'FINISHED';
  } else {
    if (distanceToCheckpoint || distanceToCheckpoint === 0) {
      distanceValue = distanceToCheckpoint.toFixed(1);
    }
    if (nextCheckpoint) {
      distanceValue = `-${distanceValue}`;
    }
  }

  return distanceValue;
};

const calculatePlayerDistanceValue = (distance: number, leaderDistance: number) => {
  let distanceValue = '';
  if (distance < 0) {
    distanceValue = 'FINISHED';
  } else if (leaderDistance < 0) {
    distanceValue = `+${distance.toFixed(1)}`;
  } else {
    distanceValue = `+${(distance - leaderDistance).toFixed(1)}`;
  }

  return distanceValue;
};

const gridTemplateColumns = '30px 36px 36px 36px 75px 160px 50px 75px 40px';
const gridTemplateColumnsMobile = '30px 30px 30px 40px 50px 100px 50px 50px 40px';

const ScoreItem: React.FC<Player & { leaderDistance: number; recordIndex: RecordIndex }> = ({
  id,
  customNumber,
  name,
  color,
  isVisible,
  positionNumber,
  leaderDistance,
  records,
  recordIndex,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trackedPlayersIds, checkpointsOrder, playMode } = useSelector((state: RootState) => ({
    trackedPlayersIds: state.players.trackedPlayersIds,
    checkpointsOrder: state.objects.checkpointsOrder,
    playMode: state.players.playMode,
  }));
  const index = playMode === 'live' ? records.length - 1 : (recordIndex as number);
  const { distance, speed, distanceToNextCheckpoint, nextCheckpoint } = records[index] ?? {};
  const isLeader = positionNumber === 1;
  const isTracked = trackedPlayersIds.includes(id);
  const distanceValue = isLeader
    ? calculateLeaderDistanceValue(
        distance ?? 0,
        distanceToNextCheckpoint ?? 0,
        nextCheckpoint ?? 0,
      )
    : calculatePlayerDistanceValue(distance ?? 0, leaderDistance ?? 0);
  const nextCheckpointName =
    isLeader && nextCheckpoint && checkpointsOrder && (distance ?? 0) >= 0
      ? checkpointsOrder[nextCheckpoint]
      : '';

  const handleTogglePlayerVisiblity = () => {
    dispatch(togglePlayerVisiblity(id));
  };

  const handleTogglePlayerTracking = () => {
    dispatch(togglePlayerTracking(id));
  };

  const handlePlayerHover = (playerId: number | null) => {
    dispatch(setHoveredPlayerId(playerId));
  };

  return (
    <Grid
      _hover={{
        bg: 'gray.50',
      }}
      _last={{ borderBottom: 0 }}
      alignItems="center"
      bg="white"
      borderBottom="1px"
      borderColor="gray.300"
      fontSize="13px"
      gridTemplateColumns={{ base: gridTemplateColumnsMobile, md: gridTemplateColumns }}
      justifyItems="center"
      onMouseEnter={() => handlePlayerHover(id)}
      onMouseLeave={() => handlePlayerHover(null)}
      opacity={isVisible ? 1 : 0.5}
      position="relative"
      py="1px"
      transition="all"
      transitionDuration="200ms"
    >
      <Box bg={color} borderRadius="md" h="20px" w="20px" />
      <GridItem>
        <IconButton
          aria-label={t('common:scoreBoard.toggleVisibility')}
          onClick={() => handleTogglePlayerVisiblity()}
          size="xs"
        >
          {isVisible ? <AiFillEye size="20px" /> : <AiFillEyeInvisible size="20px" />}
        </IconButton>
      </GridItem>
      <GridItem>
        <IconButton
          aria-label={t('common:scoreBoard.toggleFocus')}
          colorScheme={isTracked ? 'primary' : 'gray'}
          onClick={() => handleTogglePlayerTracking()}
          size="xs"
        >
          <AiOutlineSearch size="20px" />
        </IconButton>
      </GridItem>
      <GridItem>{positionNumber ?? '-'}</GridItem>
      <GridItem>{customNumber ?? '-'}</GridItem>
      <GridItem>{name ?? '-'}</GridItem>
      <GridItem>{speed ? `${speed} kt` : '-'}</GridItem>
      <GridItem textAlign="center">
        <Text>{distanceValue ?? '-'}</Text>
        <Text>{nextCheckpointName}</Text>
      </GridItem>
    </Grid>
  );
};

const GridSortItem: React.FC<{
  sortKey: ScoreboardSortBy;
  children: React.ReactNode;
}> = ({ sortKey, children }) => {
  const dispatch = useDispatch();
  const { scoreboardSortBy } = useSelector((state: RootState) => ({
    scoreboardSortBy: state.players.scoreboardSortBy,
  }));
  const isVisible = [`${sortKey}Desc`, `${sortKey}Asc`].includes(scoreboardSortBy);

  const handleChangeSortScoreboard = (sortBy: ScoreboardSortBy) => {
    dispatch(setScoreboardSortBy(sortBy));
  };

  return (
    <GridItem
      alignItems="center"
      cursor="pointer"
      display="flex"
      onClick={() => handleChangeSortScoreboard(sortKey)}
    >
      {children}
      <Box
        ml="1"
        opacity={isVisible ? '1' : '0'}
        transform={scoreboardSortBy.includes('Asc') ? 'rotate(180deg)' : ''}
        transition="all"
        transitionDuration="200ms"
      >
        <IoIosArrowDown color="white" size="14px" />
      </Box>
    </GridItem>
  );
};

const ScoreBoard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });
  const { playersData, recordIndex, playMode } = useSelector(({ players }: RootState) => players);
  const leaderDistance = useMemo(
    () => getLeaderDistance(playersData ?? [], playMode === 'live' ? 'live' : recordIndex),
    [playersData, recordIndex],
  );
  const isAllVisible = playersData?.some(({ isVisible }) => isVisible) ?? false;

  const handleToggleVisiblity = () => {
    dispatch(togglePlayerVisiblity('all'));
  };

  const handlePlayerTracking = () => {
    dispatch(togglePlayerTracking('all'));
  };

  return (
    <Box opacity="0.7" position="relative" width={{ base: '420px', md: '540px' }}>
      <Grid
        alignItems="center"
        bg="primary.500"
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
        color="white"
        fontWeight="bold"
        gridTemplateColumns={{ base: gridTemplateColumnsMobile, md: gridTemplateColumns }}
        justifyItems="center"
        py="2"
        transition="all"
        transitionDuration="200ms"
      >
        <GridItem />
        <GridItem cursor="pointer" onClick={handleToggleVisiblity}>
          {isAllVisible ? <AiFillEye size="20px" /> : <AiFillEyeInvisible size="20px" />}{' '}
        </GridItem>
        <GridItem cursor="pointer" onClick={handlePlayerTracking}>
          <AiOutlineSearch size="20px" />
        </GridItem>
        <GridSortItem sortKey="byPosition">#</GridSortItem>
        <GridSortItem sortKey="byNumber">{t('common:scoreBoard.number')}</GridSortItem>
        <GridSortItem sortKey="byName">{t('common:scoreBoard.name')}</GridSortItem>
        <GridItem>Kts</GridItem>
        <GridItem>{t('common:scoreBoard.distance')}.</GridItem>
        <GridItem display={{ base: 'none', lg: 'block' }}>
          <IconButton
            aria-label={t('common:scoreBoard.toggleScoreBoard')}
            colorScheme="primary"
            onClick={onToggle}
            size="sm"
          >
            <Box
              transform={isOpen ? 'rotate(180deg)' : ''}
              transition="all"
              transitionDuration="200ms"
            >
              <IoIosArrowDown color="white" size="20px" />
            </Box>
          </IconButton>
        </GridItem>
      </Grid>
      <Collapse in={isOpen}>
        <Box
          maxH={{
            lg: 'calc(100vh - 60px - 290px)',
            sm: 'calc(100vh - 60px - 220px)',
            base: 'calc(100vh - 60px - 290px)',
          }}
          overflowX="hidden"
          overflowY="auto"
        >
          {playersData &&
            playersData?.map((dev) => {
              const data = { ...dev, leaderDistance, recordIndex };
              return <ScoreItem key={`Device_${dev.id}`} {...data} />;
            })}
        </Box>
      </Collapse>
      <Box
        bg="primary.500"
        borderBottomLeftRadius="md"
        borderBottomRightRadius="md"
        bottom="0"
        color="white"
        textAlign="center"
        w="full"
      >
        <Text fontSize="12px" py="2px">
          {t('common:scoreBoard.unofficialResults')}
        </Text>
      </Box>
    </Box>
  );
};

const DesktopWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { playersData, isScoreboardOpen } = useSelector((state: RootState) => ({
    playersData: state.players.playersData,
    isScoreboardOpen: state.appSettings.isScoreboardOpen,
  }));

  return (
    <Box
      left={{ base: isScoreboardOpen ? '2' : '-100%', lg: playersData?.length ? '3' : '-100%' }}
      maxW="95vw"
      overflowX="auto"
      position="absolute"
      top="4"
      transition="all 200ms"
      zIndex="1000"
    >
      {children}
    </Box>
  );
};

const Wrapper: React.FC<{ mapRef: MapRef }> = ({ mapRef }) => {
  const { trackedPlayersIds, playersData, recordIndex, playMode } = useSelector(
    ({ players }: RootState) => players,
  );

  useEffect(() => {
    if (trackedPlayersIds.length) {
      const trackedPlayers = playersData.filter(({ id }) => trackedPlayersIds.includes(id));
      const bounds = getPlayersCurrentBounds(
        trackedPlayers,
        playMode === 'live' ? playMode : recordIndex,
      );
      if (mapRef.current && bounds) {
        const currentMapZoom = mapRef.current.getZoom();
        const maxZoom = currentMapZoom < 16 ? 18 : currentMapZoom;
        mapRef.current.fitBounds(bounds, {
          maxZoom,
        });
      }
    }
  }, [trackedPlayersIds, recordIndex, playersData]);

  return (
    <DesktopWrapper>
      <ScoreBoard />
    </DesktopWrapper>
  );
};

export default Wrapper;
