export enum AvailableMaps {
  'GoogleRoadmap' = 'GoogleRoadmap',
  'GoogleSatellite' = 'GoogleSatellite',
  'GoogleHybrid' = 'GoogleHybrid',
  'OsmRoadmap' = 'OsmRoadmap',
  'OsmLight' = 'OsmLight',
  'OsmDark' = 'OsmDark',
}

export enum AvailableLanguages {
  'English' = 'English',
  'Polish' = 'Polish',
}

export enum GoogleMapDisc {
  'Roadmap' = 'm',
  'AlternativeRoadmap' = 'r',
  'RoadsOnly' = 'h',
  'SatelliteOnly' = 's',
  'Terrain' = 'p',
  'TerrainOnly' = 't',
  'Hybrid' = 'y',
}

export enum ObjectPointTypes {
  StartPointsA = 101,
  StartPointsB = 102,
  FinishPointsA = 103,
  FinishPointsB = 104,
  MixedPointStart = 105,
  MixedPointFinish = 106,
}

export const shortLanguagesDisc = {
  [AvailableLanguages.English]: 'en',
  [AvailableLanguages.Polish]: 'pl',
};
