/* eslint-disable no-nested-ternary */
import { Box, HStack, IconButton, Text } from '@chakra-ui/react';
import { Select } from 'components';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosFastforward, IoIosPause, IoIosPlay, IoMdRefresh } from 'react-icons/io';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setRecordIndex } from 'state-actions';
import { RootState } from 'types';
import { getAllRecordsTimestamps } from 'utils';

dayjs.extend(duration);
dayjs.extend(utc);

const { createSliderWithTooltip } = Slider;
const TooltipSlider = createSliderWithTooltip(Slider);

const playingSpeeds = [1, 5, 10, 20, 50];
const playingSpeedOptions = playingSpeeds.map((value) => ({ value, label: `x${value}` }));

const getTooltipValue = (timestamps: string[], value: number) => {
  const startDay = dayjs.utc(timestamps[value]);
  const date = startDay.local().format('DD.MM.YYYY HH:mm:ss');

  const time = dayjs.duration(value, 'seconds').format('HH:mm:ss');

  return (
    <Box h="64px">
      <Text mb="1" px="2" whiteSpace="nowrap">
        {time}
      </Text>
      <Text px="2" whiteSpace="nowrap">
        {date}
      </Text>
    </Box>
  );
};

let intervalId: ReturnType<typeof setInterval> | null = null;

enum Actions {
  'IncrementValue' = 'IncrementValue',
  'DecrementValue' = 'DecrementValue',
  'SetValue' = 'SetValue',
  'SetMax' = 'SetMax',
  'SetRaceId' = 'SetRaceId',
  'SetPlayingSpeed' = 'SetPlayingSpeed',
  'TogglePlaying' = 'TogglePlaying',
}
interface Action {
  type: Actions;
  payload?: number;
}
interface State {
  raceId: null | number;
  isPlaying: boolean;
  value: number;
  max: number;
  playingSpeed: number;
}

const initalState: State = {
  raceId: null,
  isPlaying: false,
  value: 0,
  max: 100,
  playingSpeed: 10,
};

const reducer = (state: typeof initalState, action: Action): typeof initalState => {
  const { type, payload } = action;
  switch (type) {
    case Actions.IncrementValue:
      return { ...state, value: state.value <= state.max - 1 ? state.value + 1 : state.value };
    case Actions.DecrementValue:
      return { ...state, value: state.value > 0 ? state.value - 1 : state.value };
    case Actions.SetValue:
      return { ...state, value: payload ?? 0 };
    case Actions.SetMax:
      return { ...state, max: payload ?? 100, value: 0 };
    case Actions.SetRaceId:
      return { ...state, raceId: payload ?? null };
    case Actions.SetPlayingSpeed:
      return { ...state, playingSpeed: payload ?? 1 };
    case Actions.TogglePlaying:
      return { ...state, isPlaying: !state.isPlaying };
    default:
      return state;
  }
};

const Player = () => {
  const { t } = useTranslation();
  const [timestamps, setTimestamps] = useState<string[]>([]);

  const dispatchState = useDispatch();
  const { raceData, recordIndex, playMode, playersData } = useSelector(
    (state: RootState) => ({
      raceData: state.race.raceData,
      recordIndex: state.players.recordIndex,
      playMode: state.players.playMode,
      playersData: state.players.playersData,
    }),
    shallowEqual,
  );
  const [{ raceId, isPlaying, max, playingSpeed, value }, dispatch] = useReducer(
    reducer,
    initalState,
  );
  const isFinished = recordIndex === max && !isPlaying;

  const marks = {
    0: {
      label: dayjs.utc(timestamps[0]).local().format('DD.MM.YYYY HH:mm:ss'),
      style: {
        transform: 'translateX(-5px)',
        color: '#999',
      },
    },
    [max]: {
      label: dayjs
        .utc(timestamps[timestamps.length - 1])
        .local()
        .format('DD.MM.YYYY HH:mm:ss'),
      style: {
        right: '0',
        left: 'unset',
        transform: 'none',
        whiteSpace: 'nowrap' as any,
        color: '#999',
      },
    },
  };

  const handlePlayClick = () => dispatch({ type: Actions.TogglePlaying });

  const handleForwardClick = () => {
    if (value + 1 <= max) {
      dispatch({ type: Actions.IncrementValue });
    }
  };

  const handleBackwardClick = () => dispatch({ type: Actions.DecrementValue });

  const handleSpeedChange = (v: number) => dispatch({ type: Actions.SetPlayingSpeed, payload: v });

  const handleChange = (v: number | number[]) =>
    dispatch({ type: Actions.SetValue, payload: v as number });

  const handleStartPlaying = () => {
    intervalId = setInterval(handleForwardClick, 1000 / playingSpeed);
  };

  const handleStopPlaying = () => {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
      return true;
    }
    return false;
  };

  const handleReplayClick = () => {
    dispatch({ type: Actions.TogglePlaying });
    handleChange(0);
  };

  useEffect(() => {
    if (isPlaying) {
      handleStartPlaying();
    } else {
      handleStopPlaying();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (isPlaying && intervalId) {
      handleStopPlaying();
      handleStartPlaying();
    }
  }, [playingSpeed]);

  useEffect(() => {
    if (raceData?.id && raceId !== raceData.id) {
      dispatch({ type: Actions.SetRaceId, payload: raceData.id });
      const timestampsArr = getAllRecordsTimestamps(playersData);
      setTimestamps(timestampsArr as string[]);
      const length = timestampsArr.length > 0 ? timestampsArr.length - 1 : 0;
      dispatch({ type: Actions.SetMax, payload: length });
    }
  }, [playersData, raceData, raceId]);

  useEffect(() => {
    dispatchState(setRecordIndex(value));
    if (value >= max) {
      handleStopPlaying();
      handlePlayClick();
    }
  }, [value]);

  useEffect(() => {
    if (isPlaying) {
      handleStopPlaying();
      handlePlayClick();
    }
  }, [raceData?.id]);

  useEffect(() => {
    if (playMode === 'live' && isPlaying) {
      handleStopPlaying();
      handlePlayClick();
    }
  }, [playMode]);

  useEffect(() => {
    return () => {
      handleStopPlaying();
    };
  }, []);

  return (
    <Box
      bg="white"
      borderColor="red.500"
      borderTop="2px"
      bottom={raceData && playMode !== 'live' ? 0 : -200}
      display="grid"
      gap={{ base: '2', sm: '4' }}
      gridTemplateColumns={{ base: '1fr', sm: '160px 1fr 80px', lg: '160px 1fr' }}
      left="0"
      pb={{ base: '3', sm: '4' }}
      position="absolute"
      pt={{ base: '6', sm: '4' }}
      px="4"
      transition="all 200ms"
      w="full"
      zIndex="1003"
    >
      <Box
        alignItems={{ base: 'flex-end', sm: 'flex-start' }}
        display="flex"
        flexDirection={{ base: 'row', sm: 'column' }}
        justifyContent="space-between"
        order={{ base: '2', sm: '1' }}
      >
        <HStack mb={{ base: 0, lg: 2 }} mt={{ base: 2, sm: 0 }} spacing="1">
          <IconButton aria-label={t('common:player.backwards')} onClick={handleBackwardClick}>
            <IoIosFastforward
              size="20px"
              style={{
                transform: 'rotate(180deg)',
              }}
            />
          </IconButton>
          <IconButton
            aria-label={t('common:player.play')}
            colorScheme="primary"
            onClick={isFinished ? handleReplayClick : handlePlayClick}
            w="60px"
          >
            {isFinished ? (
              <IoMdRefresh size="20px" />
            ) : isPlaying ? (
              <IoIosPause size="20px" />
            ) : (
              <IoIosPlay size="20px" />
            )}
          </IconButton>
          <IconButton aria-label={t('common:player.forwards')} onClick={handleForwardClick}>
            <IoIosFastforward size="20px" />
          </IconButton>
        </HStack>
        <Box
          display={{
            base: 'block',
            sm: 'none',
            lg: 'block',
          }}
          maxW="135px"
        >
          <Select
            hideLabelOnMobile
            label={t('common:player.playingSpeed')}
            labelFontSize="xs"
            name="playingSpeed"
            onChange={(v) => handleSpeedChange(v as number)}
            options={playingSpeedOptions}
            value={playingSpeed}
          />
        </Box>
      </Box>
      <Box mb={{ base: '4', sm: '0' }} mt={{ base: '0', sm: '4' }} order="1">
        <TooltipSlider
          marks={marks}
          max={max}
          min={0}
          onChange={handleChange}
          tipFormatter={() => getTooltipValue(timestamps, value)}
          tipProps={{
            prefixCls: 'rc-slider-tooltip',
          }}
          value={value}
        />
      </Box>
      <Box
        display={{
          base: 'none',
          sm: 'block',
          lg: 'none',
        }}
        maxW="135px"
        order={3}
      >
        <Select
          hideLabelOnMobile
          label={t('common:player.playingSpeed')}
          labelFontSize="xs"
          name="playingSpeed"
          onChange={(v) => handleSpeedChange(v as number)}
          options={playingSpeedOptions}
          value={playingSpeed}
        />
      </Box>
    </Box>
  );
};

export default Player;
