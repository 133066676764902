import { createSlice } from '@reduxjs/toolkit';
import {
  DeviceDto,
  LiveDevicePingDto,
  Payload,
  Player,
  PlayMode,
  RaceDto,
  Record,
  RecordIndex,
  ScoreboardSort,
  ScoreboardSortBy,
  TrailCord,
} from 'types';
import { mapEventPlayers, mapPingToRecord, preparePlayersData, sortPlayers } from 'utils';

const recalculatePositions = (players: Player[], recordIndex: number | 'live') => {
  return [...players]
    .sort((playerA, playerB) => {
      const indexA = recordIndex === 'live' ? playerA.records.length - 1 : recordIndex;
      const indexB = recordIndex === 'live' ? playerB.records.length - 1 : recordIndex;
      const playerACurrentRecord = (playerA.records[indexA] as Record) ?? {};
      const playerBCurrentRecord = (playerB.records[indexB] as Record) ?? {};

      return (playerACurrentRecord.distance ?? 0) - (playerBCurrentRecord.distance ?? 0);
    })
    .map((player, i) => ({ ...player, positionNumber: i + 1 }));
};

const toggleAllPlayersVisiblity = (players: Player[]) => {
  const allIsVisible = players.some(({ isVisible }) => isVisible);
  if (allIsVisible) {
    return players.map((player) => ({ ...player, isVisible: false }));
  }
  return players.map((player) => ({ ...player, isVisible: true }));
};

const toggleAllPlayersTracking = (players: Player[], trackedIds: number[]) => {
  const isTrackedAll = trackedIds.length === players.length;
  if (isTrackedAll) {
    return [];
  }
  return players.map(({ id }) => id);
};

export interface PlayersState {
  playersData: Player[];
  scoreboardSortBy: ScoreboardSort;
  hoveredPlayerId: number | null;
  trackedPlayersIds: number[];
  recordIndex: RecordIndex;
  playMode: PlayMode;
}

const initialState: PlayersState = {
  playersData: [],
  scoreboardSortBy: 'byPositionAsc',
  hoveredPlayerId: null,
  trackedPlayersIds: [],
  recordIndex: 0,
  playMode: 'history',
};

const reducers = {
  setPlayersData: {
    prepare: (race: RaceDto) => {
      return { payload: preparePlayersData(race) };
    },
    reducer: (state: PlayersState, { payload }: Payload<Player[]>) => {
      state.playersData = payload;
    },
  },
  setPlayersLiveData: {
    prepare: (eventDevices: DeviceDto[]) => {
      return { payload: mapEventPlayers(eventDevices) };
    },
    reducer: (state: PlayersState, { payload }: Payload<Player[]>) => {
      state.playersData = payload;
    },
  },
  togglePlayerVisiblity: (state: PlayersState, { payload }: Payload<number | 'all'>) => {
    let updatedPlayers;
    if (payload === 'all') {
      const players = state.playersData;
      updatedPlayers = toggleAllPlayersVisiblity(players);
    } else {
      updatedPlayers = state.playersData.map((player) =>
        player.id === payload
          ? {
              ...player,
              isVisible: !player.isVisible,
            }
          : player,
      );
    }

    state.playersData = updatedPlayers;
  },
  togglePlayerTracking: (state: PlayersState, { payload }: Payload<number | 'all'>) => {
    let updatedTracked;

    switch (payload) {
      case 'all': {
        const { playersData, trackedPlayersIds } = state;
        updatedTracked = toggleAllPlayersTracking(playersData, trackedPlayersIds);
        break;
      }
      default: {
        if (state.trackedPlayersIds.includes(payload)) {
          updatedTracked = state.trackedPlayersIds.filter((id) => id !== payload);
        } else {
          updatedTracked = [...state.trackedPlayersIds, payload];
        }
      }
    }
    state.trackedPlayersIds = updatedTracked;
  },
  setRecordIndex: (state: PlayersState, { payload }: Payload<RecordIndex>) => {
    state.recordIndex = payload;
    state.playMode = (payload === 'live' ? 'live' : 'history') as PlayMode;
    if (payload !== 'live') {
      const { playersData, scoreboardSortBy } = state;
      const newPlayersPositions = sortPlayers(
        recalculatePositions(playersData, payload),
        scoreboardSortBy,
      );
      state.playersData = newPlayersPositions;
    }
  },
  setPlayMode: (state: PlayersState, { payload }: Payload<PlayMode>) => {
    state.recordIndex = 0;
    state.playMode = payload;
  },
  setHoveredPlayerId: (state: PlayersState, { payload }: Payload<number | null>) => {
    state.hoveredPlayerId = payload;
  },
  setScoreboardSortBy: (state: PlayersState, { payload }: Payload<ScoreboardSortBy>) => {
    let newSortKey: ScoreboardSort = `${payload}Asc`;
    if (state.scoreboardSortBy.includes(payload) && state.scoreboardSortBy !== `${payload}Desc`) {
      newSortKey = `${payload}Desc`;
    }

    const players = state.playersData;
    const sorted = sortPlayers(players, newSortKey);

    state.playersData = sorted;
    state.scoreboardSortBy = newSortKey;
  },
  pushPlayerRecord: {
    prepare: (ping: LiveDevicePingDto) => {
      return { payload: mapPingToRecord(ping) };
    },
    reducer: (state: PlayersState, { payload }: Payload<Record>) => {
      const updatedPlayersData =
        state.playersData?.map((player) =>
          player.id === payload.id
            ? {
                ...player,
                records: [...player.records, payload],
                trailCords: [...player.trailCords, [payload.long, payload.lat] as TrailCord],
              }
            : player,
        ) ?? [];

      const { recordIndex, scoreboardSortBy, playMode } = state;

      state.playersData = sortPlayers(
        recalculatePositions(updatedPlayersData, playMode === 'live' ? playMode : recordIndex),
        scoreboardSortBy,
      );
    },
  },
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers,
});

export const {
  setPlayersData,
  setPlayersLiveData,
  togglePlayerVisiblity,
  togglePlayerTracking,
  setRecordIndex,
  setHoveredPlayerId,
  setScoreboardSortBy,
  setPlayMode,
  pushPlayerRecord,
} = playerSlice.actions;
export default playerSlice.reducer;
