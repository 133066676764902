import { Box, HStack, IconButton, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  AiOutlineArrowsAlt,
  AiOutlineOrderedList,
  AiOutlineSetting,
  AiOutlineTrophy,
} from 'react-icons/ai';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Screenfull from 'screenfull';
import { toggleRaceListDrawer, toggleScoreboardDrawer } from 'state-actions';
import { RootState } from 'types';
import flotaLogoSrc from '../../assets/images/logo.png';

interface HeaderProps {
  onSettingsClick: () => void;
}

const Header: React.FC<HeaderProps> = ({ onSettingsClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventData, raceData, isRaceListOpen, isScoreboardOpen, playMode } = useSelector(
    ({ event, race, appSettings, players }: RootState) => ({
      eventData: event.eventData,
      raceData: race.raceData,
      isRaceListOpen: appSettings.isRaceListOpen,
      isScoreboardOpen: appSettings.isScoreboardOpen,
      playMode: players.playMode,
    }),
    shallowEqual,
  );

  const handleFullscreen = () => {
    if (Screenfull.isEnabled) {
      Screenfull.toggle();
    }
  };

  const { logoSrc, eventInformation, name } = eventData ?? {};
  const trimmedEventInformation = eventInformation ? eventInformation.trim() : '';

  return (
    <Box
      alignItems="center"
      borderBottom="2px"
      borderColor="primary.500"
      display="flex"
      h="60px"
      justifyContent="space-between"
      px="3"
      w="full"
    >
      <HStack alignItems="center" display="flex" spacing="4">
        <Image alt="Flota Online" maxW="118px" src={flotaLogoSrc} />
        {name && (
          <>
            <Box
              bg="primary.500"
              display={{
                base: 'none',
                sm: 'block',
              }}
              h="30px"
              w="1px"
            />
            <Text
              color="primary.500"
              display={{
                base: 'none',
                sm: 'block',
              }}
              fontWeight="bold"
            >
              {name}
            </Text>
          </>
        )}
        {logoSrc && (
          <>
            <Box
              bg="primary.500"
              display={{
                base: 'none',
                md: 'block',
              }}
              h="30px"
              w="1px"
            />
            <Image
              alt="Flota Online"
              display={{
                base: 'none',
                md: 'block',
              }}
              maxH="45px"
              maxW="100px"
              src={logoSrc}
            />
          </>
        )}
        {trimmedEventInformation && (
          <>
            <Box
              bg="primary.500"
              display={{
                base: 'none',
                lg: 'block',
              }}
              h="30px"
              w="1px"
            />
            <Text
              color="primary.500"
              display={{
                base: 'none',
                lg: 'block',
              }}
              fontWeight="bold"
            >
              {trimmedEventInformation}
            </Text>
          </>
        )}
      </HStack>
      <Box>
        <HStack spacing="2">
          {(raceData || playMode === 'live') && (
            <IconButton
              aria-label={t('common:toggleFullscreen')}
              colorScheme={isScoreboardOpen ? 'primary' : 'gray'}
              display={{
                base: 'flex',
                lg: 'none',
              }}
              onClick={() => dispatch(toggleScoreboardDrawer(!isScoreboardOpen))}
            >
              <AiOutlineTrophy size="20px" />
            </IconButton>
          )}
          <IconButton
            aria-label={t('common:toggleFullscreen')}
            colorScheme={isRaceListOpen ? 'primary' : 'gray'}
            display={{
              base: 'flex',
              lg: 'none',
            }}
            onClick={() => dispatch(toggleRaceListDrawer(true))}
          >
            <AiOutlineOrderedList size="20px" />
          </IconButton>
          <IconButton
            aria-label={t('common:toggleFullscreen')}
            display={{
              base: 'none',
              lg: 'flex',
            }}
            onClick={handleFullscreen}
          >
            <AiOutlineArrowsAlt size="20px" />
          </IconButton>
          <IconButton aria-label={t('common:openSettingsModal')} onClick={onSettingsClick}>
            <AiOutlineSetting size="20px" />
          </IconButton>
        </HStack>
      </Box>
    </Box>
  );
};

export default Header;
