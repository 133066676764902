import { apiUrl } from 'api';
import { divIcon, icon } from 'leaflet';
import { CSSProperties } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Circle, Marker } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { ObjectPointTypes, RaceObject, RootState } from 'types';

const labelStyles: CSSProperties = {
  background: 'rgba(0,0,0,.7)',
  color: '#fff',
  padding: '4px 6px',
  whiteSpace: 'nowrap',
  display: 'flex',
  position: 'absolute',
  fontSize: '10px',
  top: 25,
  left: 25,
};

const getIconUrl = (objectType: number) => {
  switch (objectType) {
    case ObjectPointTypes.StartPointsA:
    case ObjectPointTypes.StartPointsB:
    case ObjectPointTypes.MixedPointStart:
      return '/Images/objects/regaty/start.png';
    case ObjectPointTypes.FinishPointsA:
    case ObjectPointTypes.FinishPointsB:
    case ObjectPointTypes.MixedPointFinish:
      return '/Images/objects/regaty/finish.png';
    default:
      return '/Images/objects/regaty/checkpoint.png';
  }
};

const ObjectMarker: React.FC<RaceObject> = ({ name, objectType, records }) => {
  const {
    appSettings: { showLabels },
    players: { playMode, hoveredPlayerId, recordIndex },
    checkpointRadius,
  } = useSelector((state: RootState) => ({
    appSettings: state.appSettings,
    players: state.players,
    checkpointRadius: state.event.eventData?.checkpointRadius,
  }));
  const index = playMode === 'live' ? records.length - 1 : (recordIndex as number);
  const { lat, long } = records[index] ?? {};
  const {
    options: { iconUrl },
  } = icon({
    iconUrl: `${apiUrl}${getIconUrl(objectType ?? 0)}`,
  });

  const html = renderToStaticMarkup(
    <div
      style={{
        position: 'relative',
      }}
    >
      {showLabels ? (
        <div
          style={{
            ...labelStyles,
            opacity: hoveredPlayerId !== null ? '.3' : '1',
          }}
        >
          {name}
        </div>
      ) : null}
      <img
        alt={name}
        src={iconUrl}
        style={{
          zIndex: 10,
        }}
      />
    </div>,
  );
  const finalIcon = divIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    className: '',
    html,
  });

  if (lat && long) {
    switch (objectType) {
      case 10:
        return (
          <>
            <Circle
              center={[long as number, lat as number]}
              pathOptions={{ fillColor: 'red', color: 'red', weight: 1 }}
              radius={checkpointRadius ?? 15}
            />
            <Marker icon={finalIcon} position={[long as number, lat as number]} />
          </>
        );
      default:
        return <Marker icon={finalIcon} position={[long as number, lat as number]} />;
    }
  } else {
    return null;
  }
};

export default ObjectMarker;
