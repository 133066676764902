/* eslint-disable prefer-spread */
import { LatLng, latLngBounds } from 'leaflet';
import {
  DeviceRecordDto,
  DeviceRecordWithTimestamp,
  Player,
  RaceObject,
  Record,
  RecordDto,
  RecordIndex,
  TrailCord,
} from 'types';

export const getArrayFirstItemLocation = (arr: { records: Record[] }[]): LatLng | null => {
  if (!arr.length) {
    return null;
  }
  const { lat, long } = arr[0].records[0] ?? {};
  if (!lat || !long) {
    return null;
  }
  return new LatLng(long, lat);
};

export const getPlayerCurrentLocation = (player: Player, recordIndex: RecordIndex) => {
  if (!player) {
    return null;
  }
  const index = recordIndex === 'live' ? player.records.length - 1 : recordIndex;
  const { lat, long } = player.records.find((_, i) => i === index) ?? {};
  if (!lat || !long) {
    return null;
  }
  return new LatLng(long, lat);
};

export const getLeaderDistance = (players: Player[], recordIndex: RecordIndex) => {
  const leaderIndex = players.findIndex(({ positionNumber }) => positionNumber === 1);
  const leaderRecordIndex =
    recordIndex === 'live' ? (players?.[leaderIndex]?.records.length ?? 0) - 1 : recordIndex;
  const leaderDistance = players?.length
    ? players?.[leaderIndex]?.records?.[leaderRecordIndex]?.distance ?? 0
    : 0;
  return leaderDistance;
};

export const getTrailCords = (deviceRecords: DeviceRecordDto[]): TrailCord[] => {
  return deviceRecords.map(({ X, Y }) => [Y, X]);
};

const emptyRecord = {
  A: undefined,
  B: null,
  C: null,
  D: null,
  E: null,
  F: null,
  X: null,
  Y: null,
};

export const getDeviceRecords = (
  records: RecordDto[],
  deviceId: number,
): DeviceRecordWithTimestamp[] => {
  return (
    records.map(({ RunDeviceRecord, Timestamp }) => ({
      ...(RunDeviceRecord.find(({ A }) => A === deviceId) ?? emptyRecord),
      timestamp: Timestamp,
    })) ?? []
  );
};

export const getLocationsByObjectType = (
  objects: RaceObject[],
  objectTypes: number[],
  recordIndex: RecordIndex,
) => {
  return objects
    .filter(({ objectType }) => (objectType ? objectTypes.includes(objectType) : false))
    .map(({ records }) => {
      const index = recordIndex === 'live' ? records.length - 1 : recordIndex;
      const { lat, long } = records?.[index] ?? { lat: null, long: null };
      return [long, lat];
    })
    .filter(([long, lat]) => lat !== null && long !== null);
};

export const getPlayersInitalBounds = (players: Player[]) => {
  const trackedPlayers = players
    .map((player) => getPlayerCurrentLocation(player, 0))
    .filter((loc) => loc !== null);
  if (trackedPlayers.length) {
    const bounds = latLngBounds(trackedPlayers as LatLng[]);
    return bounds;
  }
  return null;
};

export const getPlayersCurrentBounds = (players: Player[], recordIndex: RecordIndex) => {
  const trackedPlayers = players
    .map((player) => getPlayerCurrentLocation(player, recordIndex))
    .filter((loc) => loc !== null);
  if (trackedPlayers.length) {
    const bounds = latLngBounds(trackedPlayers as LatLng[]);
    return bounds;
  }
  return null;
};

export const getAllRecordsTimestamps = (players: Player[]) => {
  const timestamps = players.map(({ records }) => records.map(({ timestamp }) => timestamp));
  const merged = ([] as any).concat.apply([], timestamps);
  const unique = Array.from(new Set(merged));
  return unique;
};
