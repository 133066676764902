import {
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { Input, Select } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { defaultAppSettings, setSetting } from 'state/slices/appSettings';
import {
  AppSettings,
  AvailableLanguages,
  AvailableMaps,
  RootState,
  shortLanguagesDisc,
} from 'types';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SwitchItem: React.FC<{
  label: string;
  name: string;
  value: boolean;
  onChange?: (value: boolean) => void;
}> = ({ label, name, value, onChange }) => {
  return (
    <FormControl alignItems="center" display="flex" justifyContent="space-between" mb="2">
      <FormLabel htmlFor={name} mb="0">
        {label}
      </FormLabel>
      <Switch
        id={name}
        isChecked={value}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
    </FormControl>
  );
};

const settingsToSkip = ['isRaceListOpen', 'isScoreboardOpen'];

const SettingsModal: React.FC<SettingsModalProps> = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const appSettings = useSelector((state: RootState) => state.appSettings);
  const { appLanguage, mapType, showLabels, showPlayersNames, showSpeeds, showTrail, trailLength } =
    appSettings;

  const handleSettingChange = (fieldName: keyof AppSettings, value: string | number | boolean) => {
    dispatch(setSetting({ fieldName, value }));
  };

  const handleLanguageChange = (value: AvailableLanguages) => {
    dispatch(setSetting({ fieldName: 'appLanguage', value }));
    localStorage.setItem('languageChangedManually', 'true');
    i18n.changeLanguage(shortLanguagesDisc[value]);
  };

  useEffect(() => {
    const settingsToSave = Object.fromEntries(
      Object.entries(appSettings)
        .filter(([field]) => !settingsToSkip.includes(field))
        .filter(
          ([field, value]) => value !== defaultAppSettings[field as keyof typeof appSettings],
        ),
    );
    localStorage.setItem('appSettings', JSON.stringify(settingsToSave));
  }, [appSettings]);

  const languageOptions = Object.values(AvailableLanguages).map((lang) => ({
    value: lang,
    label: t(`common:languages.${lang}`),
  }));

  const mapTypeOptions = Object.values(AvailableMaps).map((mt) => ({
    value: mt,
    label: t(`common:mapTypes.${mt}`),
  }));

  useEffect(() => {
    const i18nLang = shortLanguagesDisc[appLanguage];
    if (i18n.language !== i18nLang) {
      i18n.changeLanguage(i18nLang);
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common:settings')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <VStack divider={<Divider />} mb="3">
            <SwitchItem
              label={t('common:settingLabels.showLabels')}
              name="showLabels"
              onChange={(value) => handleSettingChange('showLabels', value)}
              value={showLabels}
            />
            <SwitchItem
              label={t('common:settingLabels.showPlayersNames')}
              name="showPlayersNames"
              onChange={(value) => handleSettingChange('showPlayersNames', value)}
              value={showPlayersNames}
            />
            <SwitchItem
              label={t('common:settingLabels.showSpeeds')}
              name="showSpeeds"
              onChange={(value) => handleSettingChange('showSpeeds', value)}
              value={showSpeeds}
            />
            <SwitchItem
              label={t('common:settingLabels.showTrail')}
              name="showTrail"
              onChange={(value) => handleSettingChange('showTrail', value)}
              value={showTrail}
            />
          </VStack>
          <Divider mb="3" />
          <VStack spacing="2">
            <Input
              label={t('common:settingLabels.trailLength')}
              max={250}
              min={50}
              name="trailLength"
              onChange={(value) => handleSettingChange('trailLength', value)}
              type="number"
              value={trailLength}
            />
            <Select
              label={t('common:settingLabels.appLanguage')}
              name="appLanguage"
              onChange={(value) => handleLanguageChange(value as AvailableLanguages)}
              options={languageOptions}
              value={appLanguage}
            />
            <Select
              label={t('common:settingLabels.mapType')}
              name="mapType"
              onChange={(value) => handleSettingChange('mapType', value)}
              options={mapTypeOptions}
              value={mapType}
            />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SettingsModal;
