import { Home } from 'pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="*" />
        <Route element={<Home />} path=":eventSlug" />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
