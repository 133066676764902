import {
  CheckpointsOrder,
  DeviceRecordDto,
  Player,
  RaceDto,
  RaceObject,
  ScoreboardSort,
} from 'types';
import { getDeviceRecords, getTrailCords, mapDeviceRecords } from 'utils';

export const sortPlayers = (players: Player[], sortBy: ScoreboardSort) => {
  switch (sortBy) {
    case 'byNameAsc':
      return players.sort((playerA, playerB) => {
        return playerA.name > playerB.name ? 1 : -1;
      });
    case 'byNameDesc':
      return players.sort((playerA, playerB) => {
        return playerA.name < playerB.name ? 1 : -1;
      });
    case 'byNumberAsc':
      return players.sort((playerA, playerB) => {
        return playerA.customNumber > playerB.customNumber ? 1 : -1;
      });
    case 'byNumberDesc':
      return players.sort((playerA, playerB) => {
        return playerA.customNumber < playerB.customNumber ? 1 : -1;
      });
    case 'byPositionAsc':
      return players.sort((playerA, playerB) => {
        return playerA.positionNumber > playerB.positionNumber ? 1 : -1;
      });
    case 'byPositionDesc':
      return players.sort((playerA, playerB) => {
        return playerA.positionNumber < playerB.positionNumber ? 1 : -1;
      });
    default:
      return players;
  }
};

export const preparePlayersData = (race: RaceDto): Player[] => {
  const { Devices, Records } = race;
  if (Devices && Records) {
    const playersData = Devices.filter(({ ObjectType }) => ObjectType === 0).map((device, i) => {
      const deviceRecords = getDeviceRecords(Records, device.Id);
      const trailCords = getTrailCords(deviceRecords as DeviceRecordDto[]);
      const mappedDeviceRecords = mapDeviceRecords(deviceRecords);
      const color: string = `rgb(${device.RedColor}, ${device.GreenColor}, ${device.BlueColor})`;

      return {
        id: device.Id,
        name: device.Name,
        customNumber: device.CustomNumber,
        records: mappedDeviceRecords,
        trailCords,
        color,
        isVisible: true,
        positionNumber: i + 1,
        iconName: device.IconName,
      };
    });
    return playersData;
  }
  return [];
};

export const prepareObjectsData = (race: RaceDto): RaceObject[] => {
  const { Devices, Records } = race;
  if (Devices && Records) {
    const objectsData = Devices.filter(({ ObjectType }) => ObjectType !== 0).map((device) => {
      const deviceRecords = mapDeviceRecords(getDeviceRecords(Records, device.Id));
      return {
        id: device.Id,
        name: device.Name,
        iconName: device.IconName,
        order: device.Order ?? [],
        records: deviceRecords,
        objectType: device.ObjectType,
      };
    });
    return objectsData;
  }
  return [];
};

export const prepareCheckpointsOrder = (objects: RaceObject[]) => {
  const checkpointsOrder: CheckpointsOrder = { 255: 'FINISH' };
  const objectsWithOrder = objects.filter(({ order }) => order.length > 0);
  for (const { order, name } of objectsWithOrder) {
    for (const index of order as number[]) {
      checkpointsOrder[index] = name;
    }
  }
  return checkpointsOrder;
};
