import {
  Box,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Spinner,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { fetchRace } from 'api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setObjectsData,
  setPlayersData,
  setPlayMode,
  setRaceData,
  toggleRaceListDrawer,
  toggleRaceLoading,
} from 'state-actions';
import { MapRef, Race, RootState } from 'types';
import { getPlayersInitalBounds } from 'utils';

dayjs.extend(utc);

interface ListItem {
  onClick?: () => void;
  isActive: boolean;
}

const RaceItem: React.FC<Race & ListItem> = ({ name, endDate, startDate, onClick, isActive }) => {
  return (
    <Box
      _hover={{
        bg: isActive ? 'blackAlpha.700' : 'blackAlpha.600',
      }}
      bg={isActive ? 'blackAlpha.700' : 'blackAlpha.500'}
      color="white"
      cursor="pointer"
      onClick={onClick}
      px="2"
      py="1"
      transition="all"
      transitionDuration="200ms"
      w="full"
    >
      <Text fontSize="14px" fontWeight="medium">
        {name}
      </Text>
      <Text>
        <Text fontSize="13px">{`${dayjs.utc(startDate).local().format('DD.MM.YYYY HH:mm')} - ${dayjs
          .utc(endDate)
          .local()
          .format('DD.MM.YYYY HH:mm')}`}</Text>
      </Text>
    </Box>
  );
};

const LiveItem: React.FC<ListItem> = ({ onClick, isActive }) => {
  const { t } = useTranslation();

  return (
    <Box
      _hover={{
        bg: isActive ? 'blackAlpha.700' : 'blackAlpha.600',
      }}
      bg={isActive ? 'blackAlpha.700' : 'blackAlpha.500'}
      color="white"
      cursor="pointer"
      onClick={onClick}
      px="2"
      py="1"
      transition="all"
      transitionDuration="200ms"
      w="full"
    >
      <Text fontSize="14px" fontWeight="medium">
        {t('common:raceList.live')}
      </Text>
      <Text fontSize="12px">{t('common:raceList.now')}</Text>
    </Box>
  );
};

const RacesNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box
      bg="blackAlpha.500"
      color="white"
      p="2"
      textAlign="center"
      transition="all"
      transitionDuration="200ms"
      w="full"
    >
      <Text fontWeight="medium">{t('common:racesNotFound')}</Text>
    </Box>
  );
};

interface RaceListProps {
  onRaceClick: (raceId: number | 'live') => void;
}

const RaceList: React.FC<RaceListProps> = ({ onRaceClick }) => {
  const { isOpen, onOpen } = useDisclosure();
  const { raceList, eventData, raceData, playMode } = useSelector(
    (state: RootState) => ({
      ...state.race,
      eventData: state.event.eventData,
      playMode: state.players.playMode,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!isOpen && !!raceList?.length) {
      onOpen();
    }
  }, [raceList]);

  return (
    <VStack spacing="1px">
      {eventData?.live && (
        <LiveItem isActive={playMode === 'live'} onClick={() => onRaceClick('live')} />
      )}
      {raceList?.map((race) => (
        <RaceItem
          key={race.id}
          isActive={race.id === raceData?.id && playMode === 'history'}
          onClick={() => onRaceClick(race.id)}
          {...race}
        />
      ))}
      {!raceList?.length && !eventData?.live && <RacesNotFound />}
    </VStack>
  );
};

const DesktopWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onOpen } = useDisclosure();
  const { raceList, raceListLoading, live } = useSelector(
    (state: RootState) => ({
      ...state.race,
      live: state.event.eventData?.live,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!isOpen && (!!raceList?.length || live) && !raceListLoading) {
      onOpen();
    }
  }, [raceList, live, raceListLoading]);

  return (
    <Box position="absolute" right="3" top="4" w="260px" zIndex={1002}>
      <Box
        _hover={{
          bg: 'primary.600',
        }}
        alignItems="center"
        bg="primary.500"
        borderBottomLeftRadius={isOpen ? '' : 'md'}
        borderBottomRightRadius={isOpen ? '' : 'md'}
        borderTopLeftRadius="md"
        borderTopRightRadius="md"
        cursor="pointer"
        display="flex"
        justifyContent="space-between"
        onClick={onToggle}
        p="2"
        transition="all"
        transitionDuration="200ms"
        userSelect="none"
      >
        <Text color="white" fontSize="lg" fontWeight="bold">
          {t('common:raceList.title')}
        </Text>
        <Box transform={isOpen ? 'rotate(180deg)' : ''} transition="all" transitionDuration="200ms">
          {raceListLoading ? (
            <Spinner color="#fff" mr="2" size="sm" />
          ) : (
            <IoIosArrowDown color="white" size="20px" />
          )}
        </Box>
      </Box>
      <Collapse in={isOpen}>
        <Box maxH="calc(100vh - 60px - 225px)" overflow="auto">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

const Wrapper: React.FC<{ mapRef: MapRef }> = ({ mapRef }) => {
  const [isMobile] = useMediaQuery('(max-width: 992px)');
  const { isRaceListOpen } = useSelector((state: RootState) => state.appSettings, shallowEqual);
  const dispatch = useDispatch();

  const handleFetchRace = async (raceId: number) => {
    try {
      if (isRaceListOpen) {
        dispatch(toggleRaceListDrawer(false));
      }
      dispatch(toggleRaceLoading(true));
      const race = await fetchRace(raceId);

      dispatch(setPlayMode('history'));
      dispatch(setRaceData(race));
      dispatch(setObjectsData(race));
      const { payload: players } = dispatch(setPlayersData(race));
      if (mapRef.current) {
        const bounds = getPlayersInitalBounds(players);
        if (bounds) {
          mapRef.current.fitBounds(bounds, {
            duration: 1,
            maxZoom: 18,
          });
        }
      }
    } catch (err) {
      dispatch(toggleRaceLoading(false));
      console.error(err);
    }
  };

  const handleRaceClick = (raceId: number | 'live') => {
    if (raceId === 'live') {
      dispatch(setPlayMode('live'));
      if (isRaceListOpen) {
        dispatch(toggleRaceListDrawer(false));
      }
    } else {
      handleFetchRace(raceId);
    }
  };

  return isMobile ? (
    <Drawer
      isOpen={isRaceListOpen}
      onClose={() => dispatch(toggleRaceListDrawer(false))}
      placement="right"
    >
      <DrawerOverlay />
      <DrawerContent bg="blackAlpha.200" shadow="none">
        <DrawerCloseButton color="white" left="2" />
        <DrawerBody mt="12" p="0">
          <RaceList onRaceClick={handleRaceClick} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <DesktopWrapper>
      <RaceList onRaceClick={handleRaceClick} />
    </DesktopWrapper>
  );
};

export default Wrapper;
