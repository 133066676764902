import { createSlice } from '@reduxjs/toolkit';
import {
  CheckpointsOrder,
  DeviceDto,
  LiveDevicePingDto,
  Payload,
  RaceDto,
  RaceObject,
  Record,
} from 'types';
import {
  mapEventObjects,
  mapPingToRecord,
  prepareCheckpointsOrder,
  prepareObjectsData,
} from 'utils';

export interface ObjectsState {
  objectsData: RaceObject[];
  checkpointsOrder: CheckpointsOrder;
}

const initialState: ObjectsState = {
  objectsData: [],
  checkpointsOrder: {},
};

const reducers = {
  setObjectsData: {
    prepare: (race: RaceDto) => {
      const raceObjects = prepareObjectsData(race);
      return {
        payload: {
          raceObjects,
          checkpointsOrder: prepareCheckpointsOrder(raceObjects),
        },
      };
    },
    reducer: (
      state: ObjectsState,
      { payload }: Payload<{ raceObjects: RaceObject[]; checkpointsOrder: CheckpointsOrder }>,
    ) => {
      state.objectsData = payload.raceObjects;
      state.checkpointsOrder = payload.checkpointsOrder;
    },
  },
  setObjectsLiveData: {
    prepare: (eventDevices: DeviceDto[]) => {
      const raceObjects = mapEventObjects(eventDevices);

      return {
        payload: {
          raceObjects,
          checkpointsOrder: prepareCheckpointsOrder(raceObjects),
        },
      };
    },
    reducer: (
      state: ObjectsState,
      { payload }: Payload<{ raceObjects: RaceObject[]; checkpointsOrder: CheckpointsOrder }>,
    ) => {
      state.checkpointsOrder = payload.checkpointsOrder;
      state.objectsData = payload.raceObjects;
    },
  },
  pushObjectRecord: {
    prepare: (ping: LiveDevicePingDto) => {
      return { payload: mapPingToRecord(ping) };
    },
    reducer: (state: ObjectsState, { payload }: Payload<Record>) => {
      const updatedPlayersData =
        state.objectsData?.map((object) =>
          object.id === payload.id
            ? {
                ...object,
                records: [...object.records, payload],
              }
            : object,
        ) ?? [];

      state.objectsData = updatedPlayersData;
    },
  },
};

const objectsSlice = createSlice({
  name: 'objects',
  initialState,
  reducers,
});

export const { setObjectsData, setObjectsLiveData, pushObjectRecord } = objectsSlice.actions;
export default objectsSlice.reducer;
