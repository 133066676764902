import { Box, useDisclosure } from '@chakra-ui/react';
import { fetchEventData, fetchEventRuns } from 'api';
import { Header, Map, Player, RaceList, ScoreBoard, SettingsModal } from 'components';
import { useWindowHeight } from 'hooks';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setEventData,
  setPlayMode,
  setRaceListData,
  toggleEventLoading,
  toggleRaceListLoading,
} from 'state-actions';
import { MapRef } from 'types';

const headerHeight = 60;

const MapLayout = () => {
  const windowHeight = useWindowHeight();
  const mapRef = useRef<MapRef>(null) as MapRef;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { eventSlug } = useParams();
  const dispatch = useDispatch();

  const getEvent = async (slug?: string) => {
    try {
      dispatch(toggleEventLoading(true));
      const data = await fetchEventData(slug);
      dispatch(setEventData(data));
      return data;
    } catch (err) {
      console.error(err);
      throw new Error('Getting event data failed');
    }
  };

  const getRaceList = async (eventId: number) => {
    try {
      dispatch(toggleRaceListLoading(true));
      const data = await fetchEventRuns(eventId);
      dispatch(setRaceListData(data));
    } catch (err) {
      console.error(err);
      throw new Error('Getting race list data failed');
    }
  };

  useEffect(() => {
    (async () => {
      const event = await getEvent(eventSlug);
      getRaceList(event.Id);
      if (event.Live) {
        dispatch(setPlayMode('live'));
      }
    })();
  }, [eventSlug]);

  useEffect(() => {
    mapRef.current?.invalidateSize();
  }, [windowHeight]);

  return (
    <>
      <SettingsModal isOpen={isOpen} onClose={onClose} />
      <Box overflow="hidden" position="relative">
        <Header onSettingsClick={onOpen} />
        <Box height={`${windowHeight - headerHeight}px`} position="relative">
          <Map mapRef={mapRef} />
          <ScoreBoard mapRef={mapRef} />
          <RaceList mapRef={mapRef} />
          <Player />
        </Box>
      </Box>
    </>
  );
};

export default MapLayout;
