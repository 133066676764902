import { FormControl, FormLabel, Select as ChakraSelect, useMediaQuery } from '@chakra-ui/react';

type SelectOptions = { value: string | number; label: string }[];
interface SelectProps {
  name: string;
  value?: string | number;
  label?: string;
  options: SelectOptions;
  labelFontSize?: string;
  onChange?: (value: string | number) => void;
  hideLabelOnMobile?: boolean;
}

const Select: React.FC<SelectProps> = ({
  label,
  value,
  name,
  options,
  onChange,
  labelFontSize = 'md',
  hideLabelOnMobile = false,
}) => {
  const [isMobile] = useMediaQuery('(max-width: 992px)');
  const isLabelHidden = isMobile && hideLabelOnMobile;

  return (
    <FormControl>
      {!!label && !isLabelHidden && (
        <FormLabel fontSize={labelFontSize} htmlFor={name} mb="0">
          {label}
        </FormLabel>
      )}
      <ChakraSelect id={name} onChange={(e) => onChange && onChange(e.target.value)} value={value}>
        {options.map(({ value: optValue, label: optLabel }) => (
          <option key={optValue} value={optValue}>
            {optLabel}
          </option>
        ))}
      </ChakraSelect>
    </FormControl>
  );
};

export default Select;
