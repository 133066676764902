import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppSettings, AvailableLanguages, AvailableMaps, Payload } from 'types';

export const defaultAppSettings: AppSettings = {
  mapType: AvailableMaps.GoogleRoadmap,
  appLanguage: AvailableLanguages.English,
  trailLength: 200,
  showLabels: true,
  showPlayersNames: true,
  showSpeeds: true,
  showTrail: true,
  isRaceListOpen: false,
  isScoreboardOpen: false,
};

const lsAppSettings = JSON.parse(localStorage.getItem('appSettings') ?? '{}');
const reducers = {
  setSetting: (
    state: AppSettings,
    {
      payload: { fieldName, value },
    }: PayloadAction<{ fieldName: keyof AppSettings; value: number | string | boolean }>,
  ) => ({
    ...state,
    [fieldName]: value,
  }),
  toggleRaceListDrawer: (state: AppSettings, { payload }: Payload<boolean>) => ({
    ...state,
    isRaceListOpen: payload,
  }),
  toggleScoreboardDrawer: (state: AppSettings, { payload }: Payload<boolean>) => ({
    ...state,
    isScoreboardOpen: payload,
  }),
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    ...defaultAppSettings,
    ...lsAppSettings,
    isRaceListOpen: false,
    isScoreboardOpen: false,
  },
  reducers,
});

export const { setSetting, toggleRaceListDrawer, toggleScoreboardDrawer } =
  appSettingsSlice.actions;
export default appSettingsSlice.reducer;
