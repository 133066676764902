import { Box } from '@chakra-ui/react';
import { connectSignalr, disconnectSignalr } from 'api';
import { MapLoader } from 'components';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapContainer, MapContainerProps, ScaleControl, ZoomControl } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { updateEventData } from 'state/slices/event';
import { pushObjectRecord, setObjectsLiveData } from 'state/slices/objects';
import { pushPlayerRecord, setPlayersLiveData } from 'state/slices/players';
import store from 'state/store';
import {
  AvailableLanguages,
  Event,
  LiveDevicePingDto,
  MapRef,
  RootState,
  shortLanguagesDisc,
} from 'types';
import {
  getPlayersInitalBounds,
  renderLayer,
  renderObjects,
  renderPlayers,
  renderTrails,
} from 'utils';

const mapCenterPoint: MapContainerProps['center'] = [52.186796, 19.105376];

const Map: React.FC<{ mapRef: MapRef }> = ({ mapRef }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const {
    event: { eventData },
    appSettings: { mapType, showTrail, trailLength },
    race: { raceData, raceLoading },
    players: { playersData, recordIndex, playMode },
    objects: { objectsData },
  } = useSelector((state: RootState) => state);

  const mapLayer = useMemo(() => renderLayer(mapType), [mapType]);
  const trails = useMemo(
    () =>
      renderTrails(playersData ?? [], playMode === 'live' ? playMode : recordIndex, trailLength),
    [playersData, recordIndex, playMode, trailLength],
  );
  const players = useMemo(() => renderPlayers(playersData ?? []), [playersData]);
  const objects = useMemo(
    () => renderObjects(objectsData ?? [], playMode === 'live' ? playMode : recordIndex),
    [objectsData, recordIndex],
  );

  const handlePushPing = (data: LiveDevicePingDto) => {
    const {
      objects: { objectsData: currentObjectsData },
    } = store.getState();

    const dataType: 'object' | 'player' =
      currentObjectsData.findIndex(({ id }) => id === data.DeviceIDinc) !== -1
        ? 'object'
        : 'player';

    if (dataType === 'object') {
      return dispatch(pushObjectRecord(data));
    }
    return dispatch(pushPlayerRecord(data));
  };

  const startLive = async (event: Event) => {
    const { payload: livePlayers } = dispatch(setPlayersLiveData(event.devices));
    dispatch(setObjectsLiveData(event.devices));
    connectSignalr(event.id, {
      onGetCurrentStatus: handlePushPing,
      onGetEventStateChange: (data) => dispatch(updateEventData(data)),
    });

    if (mapRef.current) {
      const bounds = getPlayersInitalBounds(livePlayers);
      if (bounds) {
        mapRef.current.fitBounds(bounds, {
          duration: 1,
          maxZoom: 18,
        });
      }
    }
  };

  useEffect(() => {
    (() => {
      if (playMode === 'live' && eventData?.id) {
        return startLive(eventData);
      }
      return disconnectSignalr();
    })();
  }, [playMode, eventData]);

  useEffect(() => {
    const isLanguageChangedManually = localStorage.getItem('languageChangedManually');
    const i18nLang = shortLanguagesDisc[i18n.language as AvailableLanguages];
    if (!isLanguageChangedManually && eventData?.language && i18nLang !== eventData.language) {
      i18n.changeLanguage(shortLanguagesDisc[eventData.language]);
    }
  }, [eventData]);

  return (
    <Box
      h={{
        lg: raceData && playMode !== 'live' ? 'calc(100% - 140px)' : 'calc(100%)',
        sm: raceData && playMode !== 'live' ? 'calc(100% - 74px)' : 'calc(100%)',
        base: raceData && playMode !== 'live' ? 'calc(100% - 124px)' : 'calc(100%)',
      }}
      transition="200ms"
      w="full"
    >
      {raceLoading && <MapLoader />}
      <MapContainer ref={mapRef} center={mapCenterPoint} zoom={4} zoomControl={false}>
        <ZoomControl position="bottomright" />
        <ScaleControl position="bottomleft" />
        {mapLayer}
        {showTrail && playersData && trails}
        {players}
        {objects}
      </MapContainer>
    </Box>
  );
};

export default Map;
