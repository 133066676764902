import { AvailableMaps } from 'types';

type MapConfig = {
  [key: string]: {
    url: string;
    attribution?: string;
  };
};

export const getMapUrl = (mapType: keyof typeof AvailableMaps, language: string) => {
  const isGoogle = [
    AvailableMaps.GoogleHybrid,
    AvailableMaps.GoogleRoadmap,
    AvailableMaps.GoogleSatellite,
  ].includes(mapType as AvailableMaps);
  return `${mapConfig[mapType].url}${isGoogle ? `&hl=${language}` : ''}`;
};

export const mapConfig: MapConfig = {
  OsmRoadmap: {
    url: `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  OsmLight: {
    url: `https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}@2x.png`,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  OsmDark: {
    url: `https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}@2x.png`,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
};
