import { FormControl, FormLabel, Input as ChakraInput } from '@chakra-ui/react';

interface InputProps {
  name: string;
  value: string | number;
  label?: string;
  type?: 'text' | 'number';
  onChange?: (value: string | number) => void;
  min?: number;
  max?: number;
}

const Input: React.FC<InputProps> = ({ label, value, name, type = 'text', onChange, min, max }) => {
  return (
    <FormControl mb="2">
      {!!label && (
        <FormLabel htmlFor={name} mb="0">
          {label}
        </FormLabel>
      )}
      <ChakraInput
        id={name}
        max={max}
        min={min}
        onChange={(e) => onChange && onChange(e.target.value)}
        type={type}
        value={value}
      />
    </FormControl>
  );
};

export default Input;
