import { ChakraProvider } from '@chakra-ui/react';
import 'leaflet/dist/leaflet.css';
import { AppRouter } from 'pages';
import { I18nextProvider } from 'react-i18next';
import { extendedTheme } from 'styles';
import i18n from './locales/i18n';
import './styles/index.css';

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={extendedTheme}>
        <AppRouter />
      </ChakraProvider>
    </I18nextProvider>
  );
};

export default App;
