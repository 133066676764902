import {
  AvailableLanguages,
  DeviceDto,
  DeviceRecordWithTimestamp,
  Event,
  EventDto,
  LiveDevicePingDto,
  LiveEventStateChangeDto,
  Player,
  Race,
  RaceDto,
  RaceList,
  RaceObject,
  Record,
} from 'types';

export const mapDeviceRecords = (deviceRecords: DeviceRecordWithTimestamp[]): Record[] => {
  return deviceRecords.map(({ A, B, C, D, E, F, X, Y, timestamp }) => ({
    id: A ?? null,
    lat: X,
    long: Y,
    direction: B,
    distance: C,
    speed: D,
    distanceToNextCheckpoint: E,
    nextCheckpoint: F,
    timestamp,
  }));
};

export const mapRaceList = (raceList: RaceDto[]): RaceList => {
  return raceList.map(({ Name, Id, EndDate, StartDate }) => ({
    name: Name,
    id: Id,
    endDate: EndDate,
    startDate: StartDate,
  }));
};

export const mapEventData = (event: EventDto): Event => {
  const language = event.Language === 1 ? AvailableLanguages.Polish : AvailableLanguages.Polish;
  return {
    id: event.Id,
    name: event.Name,
    eventInformation: event.EventInformation ?? null,
    checkpointRadius: event.CheckpointRadius,
    live: event.Live,
    logoRedirect: event.LogoRedirect,
    logoSrc: event.LogoPath,
    devices: event.Devices,
    language,
  };
};

export const mapRaceData = (race: RaceDto): Race => {
  return {
    id: race.Id,
    name: race.Name,
    startDate: race.StartDate,
    endDate: race.EndDate,
  };
};

export const mapEventPlayers = (devices: DeviceDto[]): Player[] => {
  return devices
    .filter(({ ObjectType }) => ObjectType === 0)
    .map(
      (
        {
          CustomNumber,
          IconName,
          Id,
          Name,
          RedColor,
          BlueColor,
          GreenColor,
          Distance,
          B,
          Lat,
          Lon,
          E,
          D,
          F,
          Identity,
          Timestamp,
        },
        i,
      ) => {
        const record: Record = {
          distance: Distance,
          direction: B,
          speed: D,
          lat: Lon,
          long: Lat,
          distanceToNextCheckpoint: E,
          id: parseInt(Identity ?? '', 10),
          nextCheckpoint: F,
          timestamp: Timestamp,
        };
        return {
          color: `rgb(${RedColor},${GreenColor},${BlueColor})`,
          customNumber: CustomNumber,
          name: Name,
          id: Id,
          iconName: IconName,
          isVisible: true,
          positionNumber: i + 1,
          records: [record],
          trailCords: [],
        };
      },
    );
};

export const mapEventObjects = (devices: DeviceDto[]): RaceObject[] => {
  return devices
    .filter(({ ObjectType }) => ObjectType !== 0)
    .map(({ IconName, Id, Name, ObjectType, Lat, Lon, B, Distance, D, E, F, Order, Timestamp }) => {
      const record: Record = {
        id: Id,
        direction: B,
        distance: Distance,
        distanceToNextCheckpoint: E,
        lat: Lon,
        long: Lat,
        nextCheckpoint: F,
        speed: D,
        timestamp: Timestamp,
      };
      return {
        id: Id,
        iconName: IconName,
        name: Name,
        objectType: ObjectType,
        order: Order ?? [],
        records: [record],
      };
    });
};

export const mapPingToRecord = ({
  DeviceIDinc,
  Lat,
  Lon,
  Direction,
  Distance,
  DistanceToNextCP,
  NextCheckpoint,
  SpeedInKts,
  Timestamp,
}: LiveDevicePingDto): Record => {
  return {
    id: DeviceIDinc,
    direction: Direction,
    lat: Lon,
    long: Lat,
    distance: Distance,
    distanceToNextCheckpoint: DistanceToNextCP,
    nextCheckpoint: NextCheckpoint,
    speed: SpeedInKts,
    timestamp: Timestamp,
  };
};

export const mapEventPing = ({
  EventInformation,
  Name,
}: LiveEventStateChangeDto): Partial<Event> => {
  return {
    name: Name ?? '',
    eventInformation: EventInformation,
  };
};
